import config from '../../../config';
import { useEffect, useState, useRef, useContext } from 'react';
import { AppContext, UserContext, MenuContext, ModalContext, ThemeContext } from '../../../contexts';

import { TextImageEpigraph, TextEditor, ImageAndEpigraph, ImageCover, Popup, Infoitem, Tag, ImageUploadGallery, ImageDeleteGallery } from './components';

import { useNavigateTo } from '../../../hooks';

import { Loader } from '../../../assets';

import { privateRoutes } from '../../../routes';
import {
    getPublicationByIdToEditAPI, updatePublicationAPI, uploadPublicationImagesAPI, deleteImageFromServerAPI,
    uploadPublicationImageGalleryAPI, deletePublicationImageGalleryAPI, getAllComponentsAPI, getAllSectionsAPI,
    getAllCategoriesAPI, getAllTagsAPI, getTagByNameAPI, uploadPublicationAudioGalleryAPI, deletePublicationAudioGalleryAPI,
    uploadPublicationAudioGalleryFileAPI, uploadPublicationVideoGalleryAPI, deletePublicationVideoGalleryAPI,updateVideoGalleryAPI, updateAudioGalleryAPI
} from '../../../services';

import { error_type } from '../../../models';

import style from './edit.module.scss';



const apiStatic = config.api_statics;


const EditPublication = () => {
    
    const localeOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hours: 'numeric', minutes: 'numeric', seconds: 'numeric'};
    
    const tagRef = useRef();
    const updateTimer = useRef();
    const galleryTimer = useRef(null);
    const paragraphRef = useRef();
    const dateRef = useRef();
    
    const [ isLoading, setIsLoading ] = useState(true);
    
    const { idEditPublication, setIdEditPublication, deleteIdEditPublication, setEditPublication, editPublication } = useContext(AppContext);
    const { components, saveComponents, sections, saveSections, categories, saveCategories, tags, saveTags } = useContext(MenuContext);
    const { deleteUser, token, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);

    const [ allTags, setAllTags ] = useState([]);

    const [ newPubDate, setNewPubDate ] = useState(null);
    const [ timeStamp, setTimeStamp ] = useState(null);
    const [ changePubDateModal, setChangePubDateModal ] = useState(false);
    const [ dateOfActivationAlreadyChanged, setDateOfActivationAlreadyChanged ] = useState(false);

    const [ showMoreParagraphs, setShowMoreParagraphs ] = useState(false);

    const [ goto ] = useNavigateTo();


    useEffect( () => {
        getAllComponentsAPI(token)
        .then(res => {
            saveComponents(res.data);
        })
        getAllSectionsAPI(token)
        .then(res => {
            saveSections(res.data);
        })
        getAllCategoriesAPI(token)
        .then(res => {
            saveCategories(res.data);
        })
        getAllTagsAPI(token)
        .then(res => {
            saveTags(res.data);
        })
    }, []);

    useEffect( () => {
        setIsLoading(true);
        if (!idEditPublication) {
            setIsLoading(false);
            goto(privateRoutes.PUBLICATIONS);
            return;
        };
        getPublicationByIdToEditAPI(idEditPublication, token)
        .then( res => {
            // console.log(res)
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(), deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 5000);
                deleteIdEditPublication();
                goto(privateRoutes.LISTPUBLICATIONS);
                return;
            };
            const tags = res?.data?.tag?.length ? res.data.tag.split('#') : [];
            setAllTags(tags);
            setEditPublication(res.data);
            setIsLoading(false);
        })
    }, []);
    
    const toSpanishDate = (timestamp) => {
        return new Date(parseInt(timestamp)).toLocaleTimeString('es-ES', localeOptions);
    };

    const handleSetDateAndTime = (e) => {
        const miliseconds = new Date(e.target.value).getTime();
        const showDate = new Date(miliseconds);

        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hours: 'numeric', minutes: 'numeric', seconds: 'numeric'};
        const diaEspaniol = new Date(parseInt(miliseconds)).toLocaleTimeString('es-ES', options);

        setNewPubDate(miliseconds);
        setTimeStamp(diaEspaniol);
        setDateOfActivationAlreadyChanged(true);
    };
    
    const updateInputContentToApi = (e) => {
        clearTimeout(updateTimer.current);
        const name = e.target.name;
        const value = e.target.value;
        const data = {
            [name]: value
        };
        setEditPublication( prev => {
            const newState = {...prev};
            newState[name] = value;
            return newState;
        })

        updateTimer.current = setTimeout( () => {
            updatePublicationAPI(data, editPublication.id, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        deleteUser(); deleteToken();
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    return;
                };
                // activateNotification(`${res.message}`, 'ok', true, 5000);
                return;
            })
        }, 900);
    };

    const updateParagraph = (e) => {
        clearTimeout(paragraphRef.current);
        const data = { [e.target.name]: e.target.value};
        setEditPublication( prev => {
            const newState = {...prev};
            newState[e.target.name] = e.target.value;
            return newState;
        })

        paragraphRef.current = setTimeout( () => {
            updatePublicationAPI(data, editPublication.id, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        deleteUser(); deleteToken();
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    return;
                }
                return;
            })
        }, 900);
    };

    const updateQuill = (e, param) => {
        clearTimeout(paragraphRef.current);
        const data = { [param]: e};
        setEditPublication( prev => {
            const newState = {...prev};
            newState[param] = e;
            return newState;
        })

        paragraphRef.current = setTimeout( () => {
            updatePublicationAPI(data, editPublication.id, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        deleteUser(); deleteToken();
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    return;
                }
                return;
            })
        }, 900);
    };

    const handleUploadImage = (e, imageName) => {
        const image = e.target.files[0];
        if (image.size > 10485760) {
            activateNotification('La imagen debe ser de 10Mb o menor', 'error', true);
            return;
        }
        const formData = new FormData();
        formData.append('enctype', 'multipart/form-data');
        formData.append('image', image);

        activateNotification('Enviando imágen', 'time', true);

        uploadPublicationImagesAPI(formData, imageName, editPublication.id, editPublication.folder, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH ) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            const data = { ...editPublication, [imageName]: res.data };
            setEditPublication(data);
            activateNotification(`${res.message}`, 'ok', true, 4000);
            return;
        })
    };

    const handleDeleteImage = (param) => {
        activateNotification('Borrando imágen del servidor', 'time', true);
        deleteImageFromServerAPI( editPublication.id, editPublication.folder, editPublication[param], param, token )
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH ) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            const data = { ...editPublication, [res.data]: null };
            setEditPublication(data);
            activateNotification(`${res.message}`, 'ok', true, 4000);
            return;
        })

    };

    const handleCreateNewAudioGallery = (e, number, audioFile) => {
        e.preventDefault();
        const position = Number(number);
        const type = e.target.type.value;
        const title = e.target.title.value || null;
        const description = e.target.description.value || null;

        activateNotification('Creando entrada de audio', 'time', true);
        
        if (type === 'iframe') {
            const source = e.target.source.value || null;
            if (!source) return;
            const data = { position, type, title, source, description };
            uploadPublicationAudioGalleryAPI(editPublication.id, data, token)
            .then(res => {
                // console.log(res)
                if (res.error) {
                    if (res.errortype === error_type.AUTH ) {
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                activateNotification(`${res.message}`, 'ok', true, 4000);
                const audios = editPublication.audiogalleries;
                const newAudios = [...audios, res.data];
                const newData = {...editPublication, audiogalleries: newAudios };
                setEditPublication(newData);
                e.target.type.value = '';
                e.target.source.value = '';
                e.target.title.value = '';
                e.target.description.value = '';
                return;
            });
        }
        if (type === 'file') {
            if (!audioFile) return;
            const formData = new FormData();
            formData.append('enctype', 'multipart/form-data');
            formData.append('audio', audioFile);
            const folder = `${editPublication.folder}/audiogallery`;
            uploadPublicationAudioGalleryFileAPI(formData, editPublication.id, position, title, description, folder, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH ) {
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                activateNotification(`${res.message}`, 'ok', true, 4000);
                const audios = editPublication.audiogalleries;
                const newAudios = [...audios, res.data];
                const newData = {...editPublication, audiogalleries: newAudios };
                setEditPublication(newData);
                e.target.type.value = '';
                e.target.title.value = '';
                e.target.description.value = '';
                return;
            })
        }        
    };

    const handleDeleteAudioGallery = (id) => {
        activateNotification('Borrando entrada de audio', 'time', true);
        deletePublicationAudioGalleryAPI(id, token)
        .then(res => {
            // console.log(res);
            if (res.error) {
                if (res.errortype === error_type.AUTH ) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            const audios = editPublication.audiogalleries;
            const newAudios = audios.filter( audio => audio.id !== id);
            const newData = {...editPublication, audiogalleries: newAudios };
            setEditPublication(newData);
            activateNotification(`${res.message}`, 'ok', true, 4000);
            return;
        })
    };

    const handleCreateNewVideoGallery = (e, number) => {
        e.preventDefault();
        const position = Number(number);
        const title = e.target.title.value || null;
        const source = e.target.source.value || null;
        const description = e.target.description.value || null;
        if (!source) return;
        const data = { position, title, source, description };

        activateNotification('Creando entrada de video', 'time', true);

        uploadPublicationVideoGalleryAPI(editPublication.id, data, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH ) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const audios = editPublication.audiogalleries;
            const newAudios = [...audios, res.data];
            const newData = {...editPublication, audiogalleries: newAudios };
            setEditPublication(newData);
            e.target.title.value = ''
            e.target.source.value = ''
            e.target.description.value = ''
            return;
        });              
    };

    const handleDeleteVideoGallery = (id) => {

        activateNotification('Borrando entrada de video', 'time', true);
        
        deletePublicationVideoGalleryAPI(id, token)
        .then(res => {
            // console.log(res);
            if (res.error) {
                if (res.errortype === error_type.AUTH ) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            const videos = editPublication.videogalleries;
            const newVideos = videos.filter( video => video.id !== id);
            const newData = {...editPublication, videogalleries: newVideos };
            setEditPublication(newData);
            activateNotification(`${res.message}`, 'ok', true, 4000);
            return;
        })
    };

    const handleUpdateGallery = (e, type, id) => {
        clearTimeout(galleryTimer.current);
        const param = e.target.name;
        const value = e.target.value;
        
        
        const galleries = editPublication[type];
        const galId = galleries.findIndex( gal => gal.id === id);
        // const filtered = editPublication[type][galId][name]= value;
        
        setEditPublication( prev => {
            const newData = {...prev};
            newData[type][galId][param] = value;
            return newData;
        });
        
        galleryTimer.current = setTimeout( () => {
        const data = {
            [param]: value
        };

        if (type === 'videogalleries') {
            activateNotification('Actualizando entrada de video', 'time', true);
            updateVideoGalleryAPI(data, id, token)
            .then(res => {
                if ( res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser();
                        deleteToken();
                        deleteIdEditPublication();
                        deleteIdEditPublication();
                        setEditPublication({});
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'ok', true, 3000);
                }
                activateNotification(`${res.message}`, 'ok', true, 3000);
                return;
            })
        }
        if (type === 'audiogalleries') {
            activateNotification('Actualizando entrada de audio', 'time', true);
            updateAudioGalleryAPI(data, id, token)
            .then(res => {
                if ( res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser();
                        deleteToken();
                        deleteIdEditPublication();
                        deleteIdEditPublication();
                        setEditPublication({});
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'ok', true, 3000);
                }
                activateNotification(`${res.message}`, 'ok', true, 3000);
                return;
            })
        }
        }, 2000);
    };

    const handleUploadImageGallery = (e, id) => {
        const image = e.target.files[0];
        if (image.size > 10485760) {
            activateNotification('La imagen debe ser de 10Mb o menor', 'error', true);
            return;
        }
        const formData = new FormData();
        formData.append('enctype', 'multipart/form-data');
        formData.append('image', image);

        activateNotification('Enviando imágen', 'time', true);
        
        uploadPublicationImageGalleryAPI(formData, id, `${editPublication.folder}/gallery`, token)
        .then( res => {
            // console.log(res);
            if (res.error) {
                if (res.errortype === error_type.AUTH ) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            const gallery = editPublication.imagegalleries;
            const updatedGallery = [...gallery, res.data]
            const data = { ...editPublication, imagegalleries: updatedGallery };
            setEditPublication(data);
            activateNotification(`${res.message}`, 'ok', true, 4000);
            return;
        })
    };

    const handleDeleteImageGallery = (id) => {
        activateNotification('Borrando imágen del servidor', 'time', true);
        deletePublicationImageGalleryAPI(id, token)
        .then( res => {
            // console.log(res);
            if (res.error) {
                if (res.errortype === error_type.AUTH ) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken(); deleteIdEditPublication(); setEditPublication({});
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            const gallery = editPublication.imagegalleries;
            const updatedGallery = gallery.filter(img => img.id !== id);
            const data = { ...editPublication, imagegalleries: updatedGallery };
            setEditPublication(data);
            activateNotification(`${res.message}`, 'ok', true, 4000);
            return;
        })
    };

    const handleSearchTag = (e) => {
        clearTimeout(tagRef.current);
        const name = e.target.value;
        if (name === '' || name === null) return;
        const isInTags = allTags.find( tag => tag === name );
        if (isInTags) return;

        tagRef.current = setTimeout( () => {
            getTagByNameAPI(name, token)
            .then(res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                const addedTag = [...allTags, res.data.name];
                const tags = { tag : addedTag.join('#')};
                updatePublicationAPI( tags, editPublication.id, token)
                .then( res => {
                    if (res.error) {
                        if (res.errortype === error_type.AUTH) {
                            deleteUser(), deleteToken();
                            activateNotification(`${res.message}`, 'error', true, 4000);
                            return;
                        }
                        activateNotification(`${res.message}`, 'error', true, 4000);
                        return;
                    }
                    setAllTags(addedTag);
                    e.target.value = '';
                    return;
                });
            })
        }, 1000);
    };

    const handleDeleteTag = (name) => {
        const deleted = allTags.filter( item => item !== name);
        const data = { tag: deleted.join('#') };
        updatePublicationAPI( data, editPublication.id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(), deleteToken();
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            setAllTags(deleted);
            return;
        });
    };

    const handleSetMenuItem = (e) => {
        const data = {
            [e.target.name]: Number(e.target.value) || null
        }
        updatePublicationAPI(data, editPublication.id, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken();
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', false, 4000);
            return;
        })
    };

    const handlePreviewPublication = () => {
        window.open(`${privateRoutes.PREVIEWPUBLICATION}`, '_blank');
    };

    const handleFinishEdition = () => {
        const data = {
            isEditingId: null,
            isEditing: false
        };
        activateNotification('Cerrando publicación', 'time', true);
        updatePublicationAPI(data, editPublication.id, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken();
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            goto(privateRoutes.LISTPUBLICATIONS);
            deleteIdEditPublication();
            return;
        })
    };

    const handlePublish = () => {
        const data = {
            isEditingId: null,
            isEditing: false,
            status: 'active'
        };
        activateNotification('Publicando nota', 'time', true);
        updatePublicationAPI(data, editPublication.id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken(); deleteIdEditPublication();
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            deleteIdEditPublication();
            goto(privateRoutes.LISTPUBLICATIONS);
            return;
        })
    };

    const handleChangePubDateAndTime = () => {
        const data = { availability: newPubDate };
        updatePublicationAPI(data, editPublication.id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken(); setEditPublication({});
                    setIdEditPublication(null);
                    setNewPubDate(null); setTimeStamp(null);
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 4000);
            setEditPublication( prev => {
                const newState = {...prev, availability: newPubDate };
                return newState;
            });
            setChangePubDateModal(false);
            setDateOfActivationAlreadyChanged(false);
            return;
        })
    };

    const handleShowPubDate = (value) => {
        const data = { showDate: value };
        updatePublicationAPI(data, editPublication.id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(); deleteToken(); setEditPublication({});
                    setIdEditPublication(null);
                    setNewPubDate(null); setTimeStamp(null);
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    return;
                };
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 4000);
            setEditPublication( prev => {
                const newState = {...prev, showDate: value };
                return newState;
            });
            return;
        })
    }

    return (
        <>
            {
                isLoading
                ?
                <Loader background={theme.alphaBackground}/>
                :
                <div className={style.main_container} style={{background: `${theme.darkBackground}`}}>
                    <div className={style.title_container} style={{color:`${theme.text}`}}>
                        <p className={style.main_title} style={{cursor: 'pointer', color:`${theme.text}`}} onClick={()=>goto(privateRoutes.PUBLICATIONS)}>
                            Publicaciones
                            <span className={style.main_subtitle} style={{color:`${theme.text}`}}> / Editar publicación</span>
                        </p>
                    </div>
                    <>
                        {
                            editPublication &&
                            <>
                                <div className={style.blocks_container_buttons} style={{background: `${theme.background}`}}>
                                    <button className={style.ok_submit} onClick={handlePreviewPublication}>Previsualizar</button>
                                    <button className={style.danger_submit} onClick={handleFinishEdition}>cerrar sin publicar</button>
                                    <button className={style.submit} onClick={handlePublish}>Publicar</button>
                                </div>

                                <div className={style.blocks_container} style={{background: `${theme.background}`}}>
                                    <p className={style.bold_text} style={{color: `${theme.text}`}}>
                                        Info general de la publicación
                                    </p>
                                    <div className={style.info}>
                                        <Infoitem title="ID de la publicación" data={editPublication?.id} theme={theme}/>
                                        <Infoitem title="Estado" data={editPublication?.status === 'pending' ? 'pendiente' : editPublication?.status === 'published' && 'publicado'} theme={theme}/>
                                        <Infoitem title="Creado por" data={editPublication?.creator?.firstName} theme={theme}/>
                                        <Infoitem title="Editado por" data={editPublication?.editor?.firstName} theme={theme}/>
                                        <Infoitem title="Redactor" data={editPublication?.writer?.firstName || '--'} theme={theme}/>
                                        <Infoitem title="Fecha de publicación" data={new Date(editPublication.availability).toLocaleString() || '--'} theme={theme}/>
                                        <Infoitem title="Fecha de creación" data={new Date(editPublication.createdAt).toLocaleString()} theme={theme}/>
                                    </div>
                                </div>

                                <div className={style.blocks_container} style={{background: `${theme.background}`}}>
                                    <p className={style.bold_text} style={{color: `${theme.text}`}}>
                                        Clasificación de la publicación
                                    </p>
                                    <div className={style.popups}>
                                        <Popup items={components} storedValue={editPublication.componentId} nameId="componentId" defaultName="componente" handler={handleSetMenuItem} theme={theme}/>
                                        <Popup items={sections}   storedValue={editPublication.sectionId}   nameId="sectionId" defaultName="sección"    handler={handleSetMenuItem} theme={theme}/>
                                        <Popup items={categories} storedValue={editPublication.categoryId}  nameId="categoryId" defaultName="categoría"  handler={handleSetMenuItem} theme={theme}/>
                                    </div>

                                </div>

                                <div className={style.blocks_container} style={{background: `${theme.background}`}}>
                                    
                                    <p className={style.bold_text} style={{color: `${theme.text}`}}>
                                        Publicación activa a partir del
                                    </p>

                                    <div className={style.date_info}>
                                        {
                                            changePubDateModal ?
                                            <>
                                                <input ref={dateRef} onChange={handleSetDateAndTime} type="datetime-local" name="availability" min="1960-01-01" max="2070-12-31" />
                                                <p style={{color: `${theme.text}`}}>{timeStamp}</p>
                                                {
                                                    dateOfActivationAlreadyChanged
                                                    &&
                                                    <button className={style.submit} onClick={handleChangePubDateAndTime}>aplicar</button>
                                                }
                                                <button className={style.cancel} onClick={() => {setChangePubDateModal(false); setDateOfActivationAlreadyChanged(false); setTimeStamp(null)} }>cancelar</button>
                                            </>
                                            :
                                            <>
                                                <p style={{color: `${theme.text}`}}>{toSpanishDate(editPublication.availability)}</p>
                                                <button className={style.submit} onClick={() => setChangePubDateModal(true)}>cambiar</button>
                                                {
                                                    editPublication.showDate
                                                    ?
                                                    <button className={style.danger_submit} onClick={() => handleShowPubDate(false)}>ocultar fecha</button>
                                                    :
                                                    <button className={style.ok_submit} onClick={() => handleShowPubDate(true)}>publicar fecha</button>
                                                }
                                            </>
                                        }
                                    </div>

                                </div>

                                <div className={`${style.blocks_container} ${style.popups_container}`} style={{background: `${theme.background}`}}>
                                <p className={style.bold_text} style={{color: `${theme.text}`}}>Etiquetas</p>
                                    
                                    <input className={style.input} name="tagname" type="text" placeholder="buscar etiqueta" onChange={handleSearchTag}/>
                                
                                    {
                                        allTags.map( tag => (
                                            <Tag key={tag.id} name={tag} theme={theme} handler={handleDeleteTag}/>
                                        ))
                                    }
                                </div>
                                
                                <div className={style.blocks_container} style={{background: `${theme.background}`}}>
                                    <div className={style.input_group}>                               
                                        <p className={style.bold_text} style={{color: `${theme.text}`}}>
                                            Título
                                        </p>
                                        <textarea
                                            name='title'
                                            className={style.textarea}
                                            style={{background: `${theme.inputBackground}`}}
                                            // placeholder={editPublication.title || ''}
                                            value={editPublication.title || ''}
                                            onChange={updateInputContentToApi}
                                        />
                                    </div>
                                    <div className={style.input_group}>                               
                                        <p className={style.bold_text} style={{color: `${theme.text}`}}>
                                            Bajada
                                        </p>
                                        <textarea
                                            name='summary'
                                            className={style.textarea}
                                            style={{background: `${theme.inputBackground}`}}
                                            // placeholder={editPublication.drop || ''}
                                            value={editPublication.summary || ''}
                                            onChange={updateInputContentToApi}
                                        />
                                    </div>

                                    <div className={style.input_group}>
                                    <ImageCover
                                        id="coverImage"
                                        publication={editPublication}
                                        imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.coverImage}`}
                                        uploadHandler={handleUploadImage}
                                        deleteHandler={handleDeleteImage}
                                        theme={theme}
                                    />                               
                                    </div>
                                  
                                    <TextImageEpigraph style={style} title="Párrafo 1"  value={editPublication?.paragraph01} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph01' theme={theme} id="image01" number='1'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image01}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph01' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 2"  value={editPublication?.paragraph02} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph02' theme={theme} id="image02" number='2'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image02}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph02' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 3"  value={editPublication?.paragraph03} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph03' theme={theme} id="image03" number='3'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image03}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph03' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 4"  value={editPublication?.paragraph04} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph04' theme={theme} id="image04" number='4'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image04}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph04' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 5"  value={editPublication?.paragraph05} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph05' theme={theme} id="image05" number='5'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image05}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph05' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 6"  value={editPublication?.paragraph06} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph06' theme={theme} id="image06" number='6'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image06}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph06' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 7"  value={editPublication?.paragraph07} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph07' theme={theme} id="image07" number='7'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image07}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph07' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 8"  value={editPublication?.paragraph08} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph08' theme={theme} id="image08" number='8'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image08}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph08' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 9"  value={editPublication?.paragraph09} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph09' theme={theme} id="image09" number='9'  publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image09}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph09' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                    <TextImageEpigraph style={style} title="Párrafo 10" value={editPublication?.paragraph10} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph10' theme={theme} id="image10" number='10' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image10}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph10' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery} updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                
                                    { !showMoreParagraphs && <button className={style.submit} onClick={() => setShowMoreParagraphs(true)}>mostrar mas párrafos</button> }

                                    { showMoreParagraphs && <button className={style.submit} onClick={() => setShowMoreParagraphs(false)}>mostrar menos párrafos</button> }
                                    {
                                        showMoreParagraphs &&
                                        <>
                                            <TextImageEpigraph style={style} title="Párrafo 11" value={editPublication?.paragraph11} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph11' theme={theme} id="image10" number='11' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image11}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph11' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 12" value={editPublication?.paragraph12} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph12' theme={theme} id="image10" number='12' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image12}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph12' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 13" value={editPublication?.paragraph13} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph13' theme={theme} id="image10" number='13' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image13}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph13' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 14" value={editPublication?.paragraph14} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph14' theme={theme} id="image10" number='14' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image14}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph14' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 15" value={editPublication?.paragraph15} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph15' theme={theme} id="image10" number='15' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image15}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph15' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 16" value={editPublication?.paragraph16} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph16' theme={theme} id="image10" number='16' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image16}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph16' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 17" value={editPublication?.paragraph17} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph17' theme={theme} id="image10" number='17' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image17}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph17' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 18" value={editPublication?.paragraph18} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph18' theme={theme} id="image10" number='18' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image18}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph18' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 19" value={editPublication?.paragraph19} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph19' theme={theme} id="image10" number='19' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image19}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph19' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                            <TextImageEpigraph style={style} title="Párrafo 20" value={editPublication?.paragraph20} modifier={updateQuill} htmlModifier={updateParagraph} param='paragraph20' theme={theme} id="image10" number='20' publication={editPublication} imageCompletePath={`${apiStatic}/${editPublication?.folder}/${editPublication.image20}`} uploadHandler={handleUploadImage} deleteHandler={handleDeleteImage} epigraphName='epigraph20' audioHandler={handleCreateNewAudioGallery} deleteAudioHandler={handleDeleteAudioGallery} videoHandler={handleCreateNewVideoGallery} deleteVideoHandler={handleDeleteVideoGallery}  updateGallery={handleUpdateGallery} updateData={updateInputContentToApi} />
                                        </>
                                    }
                                </div>

                                {/* GALERIA DE IMAGENES */}

                                <div className={style.blocks_container} style={{background: `${theme.background}`}}>

                                    <p className={style.bold_text} style={{color: `${theme.text}`}}>
                                        Galería de imágenes
                                    </p>
                                    <div className={style.input_group}>
                                        <div className={style.image_gallery}>
                                            {
                                                editPublication?.imagegalleries?.map( image => {
                                                    return (
                                                        <ImageDeleteGallery
                                                            key={image.id}
                                                            id={image.id}
                                                            imageCompletePath={`${apiStatic}/${image.image}`}
                                                            deleteHandler={handleDeleteImageGallery}
                                                            theme={theme}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    
                                    <div className={style.input_group}>
                                        <ImageUploadGallery id={editPublication.id} uploadHandler={handleUploadImageGallery} theme={theme}/>
                                    </div>
                                </div>

                                <div className={style.blocks_container_buttons} style={{background: `${theme.background}`}}>
                                    <button className={style.ok_submit} onClick={handlePreviewPublication}>Previsualizar</button>
                                    <button className={style.danger_submit} onClick={handleFinishEdition}>cerrar sin publicar</button>
                                    <button className={style.submit} onClick={handlePublish}>Publicar</button>
                                </div>

                            </>
                        }
                    </>
                </div>
            }
        </>
    )
};

export default EditPublication;


    // const handleMouseDown = (e) => {
    //     const start = e.target.selectionStart;
    //     const end = e.target.selectionEnd;
    //     const content = e.target.value;
    //     const selected = content.substring(start, end);
    //     const formattedText = `<b>${selected}</b>`;
    //     const newText = content.slice(start, end);
    //     const finished = content.replace(newText, formattedText)
    //     console.log('palabra formateada: ',finished)
    //     setEditPublication( prev => {
    //         const newState = {...prev};
    //         newState['summary'] = finished;
    //         return newState;
    //     })
    // };