import { useState, useEffect, useRef, useContext } from 'react';

import { MainLayOut } from '../../../layouts';
import { DeleteIcon, Loader, SearchIcon } from '../../../assets';

import { AppContext, UserContext, ModalContext, ThemeContext } from '../../../contexts';
import { useNavigateTo } from '../../../hooks';

import { allPublicationsAPI, getAllPublicationsByParamAPI, updatePublicationAPI, deletePublicationFromAPI } from '../../../services/';
import { privateRoutes } from '../../../routes';
import { error_type } from '../../../models';

import { Results, PublicationRow } from './components';

import style from './list.module.scss';



const ListPublications = () => {

    const [ goto ] = useNavigateTo();

    const {
        allPublications, setAllPublications,
        setIdEditPublication, setEditPublication,
        pubsPerPage, setPubsPerPage,
        currentPage, setCurrentPage,
        totalPublications, setTotalPublications,
        totalPages, setTotalPages
    } = useContext(AppContext);

    const { user, token, deleteUser, deleteToken } = useContext(UserContext);
    const { activateNotification } = useContext(ModalContext);
    const { theme } = useContext(ThemeContext);

    const [ isLoading, setIsLoading ] = useState(false);    

    const [ inputSearch, setInputSearch ] = useState('');
    const [ param, setParam ] = useState('all');
    const valueRef = useRef(null);
    
    const setPageValue = (e) => {
        const value = Number(e.target.value);
        if (!isNaN(value) && value > 0 && value <= totalPages ) {
            setCurrentPage(value);
            searchPubsByParam(value);
        };
        return;
    };

    const [ deleteModal, setDeleteModal ] = useState(false);
    const [ idToDelete, setIdToDelete ] = useState(null);


    const handleEditPublicationById = (id) => {
        setIdEditPublication(id);
        setEditPublication({});
        goto(privateRoutes.EDITPUBLICATION);
    };

    const handleShowPubInFeed = (e, id) => {
        activateNotification('modificando publicación', 'time', false);
        const values = {
            "true": true,
            "false": false
        };
        const name = e.target.name;
        const value = e.target.value;
        const data = {
            [name]: values[value]
        };
        updatePublicationAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken();
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const updatedState = allPublications.map( pub => {
                if ( pub.id === id ) {
                    return {...pub, inFeed: values[value] }
                };
                return pub;
            });
            setAllPublications(updatedState);
            return;
        });
    };
    
    const handlePlayPausePublication = (id, status) => {
        activateNotification('', 'time', false);
        const data = { status };
        updatePublicationAPI(data, id, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken();
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            activateNotification(`${res.message}`, 'ok', true, 4000);
            const updatedState = allPublications.map( pub => {
                if ( pub.id === id ) {
                    return {...pub, status }
                };
                return pub;
            });
            setAllPublications(updatedState);
            return;
        })
    };

    const handleOpenDeleteModal = (id) => {
        setDeleteModal(true);
        setIdToDelete(id);
    };

    const handleCloseDeleteModal = () => {
        setIdToDelete(null);
        setDeleteModal(false);
    };

    const handleDeletePublication = () => {
        deletePublicationFromAPI(idToDelete, token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 4000);
                    deleteUser(); deleteToken();
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 4000);
                return;
            }
            setAllPublications( prev => {
                const deletedPub = prev.filter( pub => pub.id !== idToDelete);
                return deletedPub;
            });
            activateNotification(`${res.message}`, 'ok', true, 4000);
            handleCloseDeleteModal();
            return;
        })
    };

    const searchPubsByParam = (page) => {
        setIsLoading(true);
        const value = valueRef.current.value;
        // const value = inputSearch;
        if (param === '') { 
            activateNotification(`Seleccioná un criterio de búsqueda.`, 'error', true, 3000);
            setIsLoading(false);
            return;
        };
        if (param === 'status') {  
            setInputSearch('');
            getAllPublicationsByParamAPI(param, 'pending', page, pubsPerPage, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        setTotalPublications('');
                        setTotalPages('');
                        setAllPublications([]);
                        setCurrentPage('');
                        setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    setIsLoading(false);
                    // goto(privateRoutes.PUBLICATIONS);
                    return;
                };
                setAllPublications(res.data.rows);
                setTotalPublications(res.data.count);
                setTotalPages(Math.ceil(res.data.count / pubsPerPage));
                setPubsPerPage(Number(res.pubsperpage));
                setCurrentPage(Number(res.currentPage));
                setIsLoading(false);
                return;
            });
            return;
        };
        if (param === 'all') {  
            setInputSearch('');  
            allPublicationsAPI(page, pubsPerPage, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        setTotalPublications('');
                        setTotalPages('');
                        setAllPublications([]);
                        setCurrentPage('');
                        setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    setIsLoading(false);
                    // goto(privateRoutes.PUBLICATIONS);
                    return;
                };
                setAllPublications(res.data.rows);
                setTotalPublications(res.data.count);
                setTotalPages(Math.ceil(res.data.count / pubsPerPage));
                setPubsPerPage(Number(res.pubsperpage));
                setCurrentPage(Number(res.currentPage));
                setIsLoading(false);
                return;
            });
            return;
        };
        if ((param === 'id' || param === 'title') && (value === '' || value === null)) { 
            activateNotification(`Por favor, escribí lo que quieras buscar`, 'error', true, 3000);
            setIsLoading(false);
            return;
        }
        if (param === 'id' || param === 'title') { 
            // console.log(param, value)   
            getAllPublicationsByParamAPI(param, value, page, pubsPerPage, token)
            .then( res => {
                if (res.error) {
                    if (res.errortype === error_type.AUTH) {
                        deleteUser(), deleteToken();
                        setTotalPublications('');
                        setTotalPages('');
                        setAllPublications([]);
                        setCurrentPage('');
                        setIsLoading(false);
                        activateNotification(`${res.message}`, 'error', true, 3000);
                        return;
                    }
                    activateNotification(`${res.message}`, 'error', true, 3000);
                    setIsLoading(false);
                    // goto(privateRoutes.PUBLICATIONS);
                    return;
                };
                setAllPublications(res.data.rows);
                setTotalPublications(res.data.count);
                setTotalPages(Math.ceil(res.data.count / pubsPerPage));
                setPubsPerPage(Number(res.pubsperpage));
                setCurrentPage(Number(res.currentPage));
                setIsLoading(false);
                return;
            })
        };
    };

    const handleGetAllPubs = (ppp) => {
        allPublicationsAPI(currentPage, ppp, token)
        .then( res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    deleteUser(), deleteToken();
                    setTotalPublications('');
                    setTotalPages('');
                    setAllPublications([]);
                    setCurrentPage('');
                    setIsLoading(false);
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 5000);
                setIsLoading(false);
                return;
            };
            setAllPublications(res.data.rows);
            setTotalPublications(res.data.count);
            setTotalPages(Math.ceil(res.data.count / ppp));
            setPubsPerPage(Number(res.pubsperpage));
            setCurrentPage(Number(res.currentPage));
            setIsLoading(false);
            return;
        })
    };

    const prevPage = () => {
        if (currentPage === 1) return;
        const page = currentPage - 1;
        searchPubsByParam(page);
    };

    const nextPage = () => {
        if (currentPage === totalPages) return;
        const page = currentPage + 1;
        searchPubsByParam(page);
    };

    const handleChangePubsPerPage = (e) => {
        setPubsPerPage(e.target.value);
        handleGetAllPubs(e.target.value);
    };
    
    useEffect(() => {
        setIsLoading(true);
        handleGetAllPubs(pubsPerPage);
    }, []);


    return (
        <MainLayOut>
            {
                isLoading
                ?
                <Loader background={theme.alphaBackground}/>
                :
                <>
                    <div className={style.title_container}>
                        <p className={style.main_title} style={{cursor: 'pointer', color:`${theme.text}`}} onClick={()=>goto(privateRoutes.PUBLICATIONS)}>
                            Publicaciones
                            <span className={style.main_subtitle} style={{color:`${theme.text}`}}> / Lista de publicaciones</span>
                        </p>
                    </div>
                    
                    <div className={style.total_and_pagination} style={{background: `${theme.background}`}}>
                        
                        <div className={style.search_elements} style={{color:`${theme.text}`}}>
                            <select className={`${style.selector} ${style.big_selector}`} onChange={(e)=>setParam(e.target.value)}>
                                <option selected={false} value='all'>buscar por</option>
                                <option selected={param === 'all' ? true : false} value='all'>todas</option>
                                <option selected={param === 'id' ? true : false} value='id'>por id</option>
                                <option selected={param === 'title' ? true : false} value='title'>por título</option>
                                <option selected={param === 'status' ? true : false} value='status'>pausadas</option>
                            </select>
                            <input
                                className={`${style.input} ${style.mid_input}`}
                                placeholder='ingresar texto'
                                ref={valueRef}
                                onChange={ (e) => setInputSearch(e.target.value)}
                                value={ inputSearch || '' }
                                onKeyDown={ (e) => { if (e.key === 'Enter') { searchPubsByParam(1) }}}
                            />
                            <button className={style.submit} onClick={() => searchPubsByParam(1)}>
                                <SearchIcon width='14px' height='14px' fill='white'/>
                            </button>
                            {
                                allPublications.length ?
                                <div onClick={() => setAllPublications([])} style={{padding: '0 6px'}}>
                                    <DeleteIcon width='18px' height='18px' fill={theme.strokeColor01}/>
                                </div>
                                :
                                <></>
                            }
                        </div>

                        <p className={style.small_light_text} style={{color: `${theme.text}`}}>Total: {totalPublications} {totalPublications > 1 ? 'publicaciones' : 'publicación'}</p>
                    </div>



                    <Results theme={theme}  pubsPerPage={pubsPerPage} currentPage={currentPage} totalPages={totalPages} handleChangePubsPerPage={handleChangePubsPerPage} setPageValue={setPageValue} prevPage={prevPage} nextPage={nextPage} />
                    <div className={style.pubs_list}>
                        {
                            allPublications.map( (publication, i) => {
                                return (
                                    <PublicationRow key={publication.id} i={i} publication={publication} theme={theme} feedHandler={handleShowPubInFeed} edithandler={handleEditPublicationById} playPauseHandler={handlePlayPausePublication} deleteHandler={handleOpenDeleteModal} user={user} />
                                )
                            })
                        }
                    </div>
                    {
                        pubsPerPage > 5
                        &&
                        <Results theme={theme}  pubsPerPage={pubsPerPage} currentPage={currentPage} totalPages={totalPages} handleChangePubsPerPage={handleChangePubsPerPage} setPageValue={setPageValue} prevPage={prevPage} nextPage={nextPage} />
                    }


                    {/* DELETE MODAL */}
                    <div className={ deleteModal ? `${style.modal_edition} ${style.on}` : `${style.modal_edition} ${style.off}`} style={{background:`${theme.alphaBackground}`}}>
                        <div className={deleteModal ? `${style.modal_block} ${style.on_content}` : `${style.modal_block} ${style.off_content}`}>
                            
                            <p className={style.text} style={{color:'#EEE'}}>Eliminar la publicación {idToDelete}?</p>
                        
                            <div className={style.modal_openclose_buttons}>
                                {/* <button className={style.cancel} onClick={handleCloseDeleteModal}>cancelar</button> */}
                                <button className={style.submit} onClick={handleDeletePublication}>ACEPTAR</button>
                                <div className={style.close_button} onClick={handleCloseDeleteModal}></div>
                            </div>

                        </div>
                    </div>
                </>
            }

        </MainLayOut>
    )
};

export default ListPublications;