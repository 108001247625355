import style from './results.module.scss';


const Results = ({ theme, pubsPerPage, currentPage, totalPages, handleChangePubsPerPage, setPageValue, prevPage, nextPage}) => {

  return (
    <div className={style.results}>

      <div className={style.pagination_per_page} style={{color: `${theme.text}`}}>
          <p className={style.small_light_text}>resultados por página</p>
          <select className={style.selector} onChange={handleChangePubsPerPage} >
              <option selected={ pubsPerPage ==  5 ? true : false } value="5">5</option>
              <option selected={ pubsPerPage == 10 ? true : false } value="10">10</option>
              <option selected={ pubsPerPage == 20 ? true : false } value="20">20</option>
              <option selected={ pubsPerPage == 30 ? true : false } value="30">30</option>
          </select>
      </div>

      <div className={style.pagination_pages}>
          <p className={style.small_light_text} style={{color: `${theme.text}`}}>página</p>
          <input className={`${style.input} ${style.small_input}`} type="text" value={currentPage} placeholder={currentPage.toString()} onChange={setPageValue} />
          <p className={style.small_light_text} style={{color: `${theme.text}`}}>de {totalPages}</p>
          <div className={style.pagination_buttons}>
              <button className={`${style.submit} ${style.prev_next} ${style.prev}`} onClick={prevPage}></button>
              <button className={`${style.submit} ${style.prev_next} ${style.next}`} onClick={nextPage}></button>
          </div>
      </div>
      
  </div>
  )
};

export default Results;
