import config from '../../../../../config';
import { AddIcon, DeleteIcon } from '../../../../../assets';

import style from './card.module.scss';


const HilightCard = ({i, id, publication, dragCard, draggedOverCard, handleSort, deleteHandler, theme}) => {
    return (
        <div
            className={style.block} style={publication.status !== 'active' ? { border: `solid 4px ${theme.strokeColor01}`, cursor: 'default', background:`${theme.background}`} : publication.availability > Date.now() ? { border: `solid 4px ${theme.strokeColor04}`, cursor: 'default', background:`${theme.background}`} : { cursor: 'default', background:`${theme.background}`}}
            draggable
            onDragStart={ () => dragCard.current = i}
            onDragEnter={ () => draggedOverCard.current = i}
            onDragEnd={handleSort}
            onDragOver={(e) => {e.preventDefault()} }
        >
            <div className={style.publication_image} style={{backgroundImage: `url(${config.api_statics}/${publication.folder}/${publication.coverImage})`}}></div>
            
            <p className={`${style.big_bold_text} ${style.card_title}`} style={{color: `${theme.text}`}}>{publication.title}</p>
            {
                publication.availability > Date.now()
                &&
                <p style={{fontSize:'14px', fontWeight:900, padding:'10px',backgroundColor:`${theme.strokeColor04}`, borderRadius:'6px'}}>Publicación activa a partir de: {new Date(publication.availability).toLocaleString()}</p>
            }
            {
                publication.status !== 'active'
                &&
                <p style={{fontSize:'14px', fontWeight:900,padding:'10px',backgroundColor:`${theme.strokeColor01}`, borderRadius:'6px'}}>Publicación INACTIVA</p>
            }
            
            <div className={style.delete_icon} onClick={() => deleteHandler(id)} style={{cursor:'pointer',background:`${theme.background}`}}>
                <DeleteIcon fill={theme.strokeColor01}/>
            </div>

        </div>         
    )
};

export default HilightCard;
