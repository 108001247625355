import { useState, useContext } from 'react';
import { AppContext, UserContext, ThemeContext, ModalContext } from '../../contexts';

import { useNavigateTo } from '../../hooks';
import { privateRoutes } from '../../routes';

import { MainLayOut } from '../../layouts';

import { newPublicationAPI } from '../../services';
import { error_type } from '../../models';

import { AddIcon, EditIcon, PublicationIcon, HilightIcon } from '../../assets';

import style from './publications.module.scss';


const Publications = () => {
    
    const medida = '26px';

    const { setIdEditPublication } = useContext(AppContext);
    const { deleteUser, token, deleteToken } = useContext(UserContext);
    const { theme } = useContext(ThemeContext);
    const { activateNotification } = useContext(ModalContext);

    const [ isCreatingNewPublication, setIsCreatingNewPublication ] = useState(false);

    const [ goto ] = useNavigateTo();


    const handleCreateNewPublication = () => {
        setIsCreatingNewPublication(true);
        activateNotification('Creando nueva publicación', 'time', true);
        newPublicationAPI(token)
        .then(res => {
            if (res.error) {
                if (res.errortype === error_type.AUTH) {
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    deleteUser(); deleteToken();
                    setIsCreatingNewPublication(false);
                    return;
                };
                if (res.errortype === error_type.CONTENTNOTCREATED) {
                    activateNotification(`${res.message}`, 'error', true, 5000);
                    setIsCreatingNewPublication(false);
                    goto(privateRoutes.PUBLICATIONS);
                    return;
                }
                activateNotification(`${res.message}`, 'error', true, 5000);
                setIsCreatingNewPublication(false);
                goto(privateRoutes.PUBLICATIONS);
                return;
            };
            activateNotification(`${res.message}`, 'ok', true, 5000);
            setIdEditPublication(res.data.id);
            setIsCreatingNewPublication(false);
            goto(privateRoutes.EDITPUBLICATION);
            return;
        })
    };


    return (
        <MainLayOut>  
          
            <div className={style.title_container}>
                <p className={style.main_title} style={{color:`${theme.text}`}}>Publicaciones</p>
            </div>

            <div className={style.blocks_container}>
                {
                    isCreatingNewPublication
                    ?
                    <div className={style.block} style={{background: `${theme.background}`}}>
                        <AddIcon width={medida} height={medida} fill={theme.strokeColor02} />
                        <p className={style.light_text} style={{color: `${theme.text}`}}>Crear nueva publicación</p>
                    </div>
                    :
                    <div onClick={handleCreateNewPublication} className={style.block} style={{background: `${theme.background}`}}>
                        <AddIcon width={medida} height={medida} fill={theme.strokeColor02} />
                        <p className={style.light_text} style={{color: `${theme.text}`}}>Crear nueva publicación</p>
                    </div>
                }
                
                {/* <div onClick={ () => goto(privateRoutes.EDITPUBLICATION)} className={style.block} style={{color: `${theme.text}`,background: `${theme.background}`}}>
                    <EditIcon width={medida} height={medida} fill={theme.strokeColor02}/>
                    <p>Editar publicacion por ID</p>
                </div> */}

                <div onClick={ () => goto(privateRoutes.LISTPUBLICATIONS)} className={style.block} style={{background: `${theme.background}`}}>
                    <PublicationIcon width={medida} height={medida} fill={theme.strokeColor06} />
                    <p className={style.light_text} style={{color: `${theme.text}`}}>Lista de publicaciones</p>
                </div>

                <div onClick={ () => goto(privateRoutes.HILIGHTS)} className={style.block} style={{background: `${theme.background}`}}>
                    <HilightIcon width={medida} height={medida} fill={theme.strokeColor04} />
                    <p className={style.light_text} style={{color:`${theme.text}`}}>Lista de destacadas</p>
                </div>
            </div>

        </MainLayOut>
    )
};

export default Publications;
