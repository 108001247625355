import { useState } from 'react';
import config from '../../../../../config';
import { EditIcon, PlayIcon, PauseIcon, DeleteIcon, AddIcon, ErrorImg, WarnImg } from '../../../../../assets';

import { localDate, localDateAndTime } from '../../../../../helpers';

import style from './row.module.scss';


const PublicationRow = ({i, publication, theme, feedHandler, edithandler, playPauseHandler, deleteHandler, user}) => {

    const [ showEditors, setShowEditors ] = useState(false);

    return (
        <div className={style.main_row_content} style={{background: i % 2 === 0 ? `${theme.background}` : `${theme.darkBackground}` }}>
            <p className={`${style.pub_id} ${style.small_bold_text}`} style={ publication?.status === 'active' ? {backgroundColor: `${theme.on}`} : {backgroundColor: `${theme.off}`} }>{publication?.id}</p>
            <div className={style.thumbnail} style={{backgroundImage:`url(${config.api_statics}/${publication?.folder}/${publication?.coverImage})`}}></div>
            <div className={style.pub_row}>
                
                <div className={style.pub_data}>
                    
                    <div className={style.id_title}>
                        <div className={style.pub_title}>
                            <p className={style.light_text} style={{color:`${theme.text}`, fontSize: '15px'}}>{publication?.title ? publication?.title : 'sin título'}</p>
                        </div>
                    </div>

                    <div className={style.pub_date_editor}>

                        <div style={{minWidth:'130px'}}>
                            <p className={style.small_light_text} style={{color:`${theme.text}`}}>activa a partir del:</p>
                            <p className={style.small_light_text} style={{color: `${new Date().getTime() < new Date(publication?.availability).getTime() ? 'red' : 'green'}`}}>
                            {/* <p className={style.small_light_text} style={{color:`${theme.primary}`}}> */}
                                { 
                                    publication?.availability &&
                                    localDateAndTime(publication?.availability)
                                }
                            </p>
                        </div>
                        
                        <div className={style.small_light_text} style={{color:`${theme.primary}`,minWidth:'130px'}}>
                            <p style={{color:`${theme.text}`}}>creada el:</p>
                            {
                                localDateAndTime(new Date(publication?.createdAt).getTime())
                            }
                            {/* <p>
                                {
                                    publication?.createdAt &&
                                    publication?.createdAt?.split('T')[0].split('-').reverse().join('/')
                                }
                                <span> </span>
                                {
                                    publication?.createdAt &&
                                    publication?.createdAt?.split('T')[1].split(':').slice(0,2).join(':')
                                }
                                <span> </span>
                                hs.
                            </p> */}
                        </div>

                        <div className={style.small_light_text} style={{color:`${theme.primary}`,minWidth:'60px'}}>
                            <p style={{color:`${theme.text}`}}>Visitas:</p>
                            <p>{publication?.totalViews}</p>
                        </div>


                        <div className={style.infeed_block}>
                            <p className={style.small_bold_text} style={{color:`${theme.text}`, width:'50px'}}>en feed</p>
                            <div className={style.infeed_selector} style={ publication?.inFeed ? {border: 'solid 3px green',width:'50px',borderRadius:'8px'} : {border: `solid 3px ${theme.primary}`,width:'50px',borderRadius:'8px'}}>
                                <select className={`${style.selector} ${style.max_selector}`} style={{width:'44px'}} name='inFeed' onChange={(e) => feedHandler(e, publication?.id)}>
                                    <option value={true} selected={publication?.inFeed ? true : false}>SI</option>                          
                                    <option value={false} selected={publication?.inFeed ? false : true}>NO</option>
                               </select>
                            </div>
                        </div>

                        
                        <div className={style.createdby_container}>
                            <img className={style.img_container} src={`${config.api_profile}/${publication?.creator?.image}`} alt={publication?.creator?.firstName} />
                            <div>
                                <p className={style.small_light_text} style={{color:`${theme.text}`}}>creado por</p>
                                <p className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.creator?.firstName}</p>
                            </div>
                        </div>

                        {
                            publication?.editor
                            &&
                            <div className={style.createdby_container}>
                                <img className={style.img_container} src={`${config.api_profile}/${publication?.editor?.image}`} alt={publication?.editor?.firstName} />
                                <div>
                                    <p className={style.small_light_text} style={{color:'orange'}}>Está editando</p>
                                    <p className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.editor?.firstName}</p>
                                </div>
                            </div>
                        }
                        {
                            publication?.editions
                            &&
                            publication?.editions.length
                            &&
                            !publication?.editor
                            ?
                            <div className={style.editions_block} onMouseEnter={ ()=> setShowEditors(true) } onMouseLeave={ () => setShowEditors(false) }>

                                <div className={style.createdby_container}>
                                    <img className={style.img_container} src={`${config.api_profile}/${publication?.editions[0]?.admin?.image}`} alt={publication?.admin?.firstName} />
                                    <div>
                                        <p className={style.small_light_text} style={{color:`${theme.text}`}}>última edición:
                                        <p className={style.mid_bold_text} style={{color:`${theme.text}`}}> {publication?.editions[0]?.admin?.firstName}</p>
                                        </p>
                                        <p className={style.small_light_text} style={{color:`${theme.primary}`}}>{localDateAndTime(new Date(publication?.editions[0]?.createdAt).getTime())}</p>
                                    </div>
                                    
                                    {
                                        publication?.editions?.length > 1
                                        &&
                                        <div>
                                            <AddIcon width='10px' height='10px'/>
                                        </div>
                                    }

                                </div>

                                {
                                    showEditors && publication?.editions?.length > 1 &&
                                    <div className={style.editors_popup}>
                                        {
                                            publication?.editions?.map((edition, i) => {
                                                if (i > 0) {
                                                    return (
                                                        <div key={edition.id}>
                                                            <div className={style.createdby_container}>
                                                                <img className={style.img_container} src={`${config.api_profile}/${edition?.admin?.image}`} alt={publication?.admin?.firstName} />
                                                                <div>
                                                                    <p className={style.small_light_text} style={{color:'white'}}>Editó: <span className={style.mid_bold_text}>{ edition?.admin?.firstName }</span></p>
                                                                    <p className={style.small_light_text} style={{color:`${theme.primary}`}}>{localDateAndTime(new Date(edition?.createdAt))}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                return null;
                                            })
                                        }
                                    </div>
                                }
                            </div>
                            :
                            <></>
                        }
                    </div>

                </div>
                
                {
                    publication?.editor
                    ?
                    <WarnImg width='24px' height='24px'/>
                    :
                    <div className={style.pub_icons}>
                    
                        <div onClick={() => edithandler(publication?.id)} style={{cursor: 'pointer'}}>
                            <EditIcon width='19px' height='19px' fill={theme.alttext} />
                        </div>
                        {
                            publication.status === 'pending' ?
                            <div onClick={() => playPauseHandler(publication?.id, 'active')} style={{cursor: 'pointer'}}>
                                <PlayIcon width='20px' height='20px' fill={theme.alttext}/>
                            </div>
                            :
                            <div onClick={() => playPauseHandler(publication?.id, 'pending')} style={{cursor: 'pointer'}}>
                                <PauseIcon width='20px' height='20px' fill={theme.alttext}/>
                            </div>
                        }
                        {
                            user?.role === 'Admin' &&
                            <div onClick={() => deleteHandler(publication?.id)} style={{cursor: 'pointer'}}>
                                <DeleteIcon width='17px' fill={theme.primary}/>
                            </div>
                        }
                    </div>
                }
              
            </div>
        </div>
    )
};

export default PublicationRow;
